/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/*===== VARIABLES CSS =====*/
:root {
    --header-height: 3rem;
    --font-semi: 600;
}

/*===== Colours =====*/
:root {
    --first-color: #4070f4;
    --second-color: #0e2431;
}

/*===== fonts and typography =====*/
:root {
    --body-font: "Poppins", sans-serif;
    --big-font-size: 2rem;
    --h2-font-size: 1.25rem;
    --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px) {
    :root {
        --big-font-size: 3.5rem;
        --h2-font-size: 2rem;
        --normal-font-size: 1rem;
    }
}

/*===== margins =====*/
:root {
    --mb-1: 0.5rem;
    --mb-2: 1rem;
    --mb-3: 1.5rem;
    --mb-4: 2rem;
    --mb-5: 2.5rem;
    --mb-6: 3rem;
}

/*===== z index =====*/
:root {
    --z-back: -10;
    --z-normal: 1;
    --z-tooltip: 10;
    --z-fixed: 100;
}

/*===== BASE =====*/
*,
::before, ::after {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    color: var(--second-color);
}
h1, h2, p {
    margin: 0;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}

/*===== CLASS CSS ===== */
.section-title {
    position: relative;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    margin-top: var(--mb-2);
    margin-bottom: var(--mb-4);
    text-align: center;
}
.section-title::after {
    position: absolute;
    content: "";
    width: 64px;
    height: 0.18rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 2rem;
    background-color: var(--first-color);
}
.section {
    padding-top: 3rem;
    padding-bottom: 2rem;
}

/*===== LAYOUT =====*/
.bd-grid {
    max-width: 1024px;
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 2rem;
    width: calc(100% - 2rem);
    margin-left: var(--mb-2);
    margin-right: var(--mb-2);
}
.l-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: #4070f4;
    box-shadow: 0 1px 4px rgba(146, 161, 176, 0.15);
}

/*===== NAV =====*/
.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);
}
@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: var(--header-height);
        right: -100%;
        width: 80%;
        height: 100%;
        padding: 2rem;
        background-color: var(--second-color);
        transition: 0.5s;
    }
}
.nav__item {
    margin-bottom: var(--mb-4);
}
.nav__link {
    position: relative;
    color: #fff;
}
.nav__link:hover {
    position: relative;
}
.nav__link:hover::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: #fff;
}
.nav__logo {
    color: #fff;
}
.nav__toggle {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}
/*Active menu*/
.active::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}

/*=== Show menu ===*/
.show {
    right: 0;
}

/*===== HOME =====*/
.home {
    height: calc(100vh - 3rem);
    row-gap: 1rem;
}
.home__data {
    align-self: center;
}
.home__title {
    font-size: var(--big-font-size);
}
.home__data > h2 {
    margin-bottom: 20px;
}
.home__title-color {
    color: var(--first-color);
}

/*BUTTONS*/
.button {
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: 0.75rem 2.5rem;
    font-weight: var(--font-semi);
    border-radius: 0.5rem;
    margin-right: 15px;
}
.button:hover {
    box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

/* ===== ABOUT =====*/
.about__container {
    grid-template-columns: repeat(3, 1fr);
    align-items: inherit;
}
.about__subtitle {
    margin-bottom: var(--mb-2);
}
.about__img {
    justify-self: center;
}
.about__img img {
    width: 200px;
    border-radius: 0.5rem;
}
.about__container > div {
    border: 1px solid gold;
    padding: 1%;
}
.projectImgs {
    width: fit-content;
    height: auto;
}

/* ===== CONTACT =====*/
.contact__input {
    width: 100%;
    font-size: var(--normal-font-size);
    font-weight: var(--font-semi);
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1.5px solid var(--second-color);
    outline: none;
    margin-bottom: var(--mb-4);
}
.contact__button {
    display: block;
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
    cursor: pointer;
    margin-left: auto;
}

/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px) {
    body {
        margin: 0;
    }
    .section {
        padding-top: 4rem;
        padding-bottom: 3rem;
    }
    .section-title {
        margin-bottom: var(--mb-6);
    }
    .section-title::after {
        width: 80px;
        top: 3rem;
    }

    .nav {
        height: calc(var(--header-height) + 1rem);
    }
    .nav__list {
        display: flex;
        padding-top: 0;
    }
    .nav__item {
        margin-left: var(--mb-6);
        margin-bottom: 0;
    }
    .nav__toggle {
        display: none;
    }
    .nav__link {
        color: #fff;
    }

    .home {
        height: 100vh;
    }
    .home__data {
        align-self: flex-end;
    }
    .home__social {
        padding-top: 0;
        padding-bottom: 2.5rem;
        flex-direction: row;
        align-self: flex-end;
    }
    .home__social-icon {
        margin-bottom: 0;
        margin-right: var(--mb-4);
    }
    .home__img {
        width: 457px;
        bottom: 15%;
    }
    .contact__form {
        width: 360px;
    }
    .contact__container {
        justify-items: center;
    }
}

@media screen and (min-width: 1024px) {
    .bd-grid {
        margin-left: auto;
        margin-right: auto;
    }
    .home__img {
        right: 10%;
    }
}

@media screen and (max-width: 800px) {
    .about__container {
        grid-template-columns: repeat(1, 1fr);
    }
}